.slider-image-container {
    padding: 1.5rem;
  }
  
  .slider-image {
    width: 80%;
    transition: transform 0.2s;
    opacity:  0.4 ;
  }
  
  .slick-center .slider-image {
    transform: scale(1.2);
    opacity:  1 ;
  }
  

.slick-slider{
  max-width: 100% !important;
}
.slick-next{
  display: none !important;
}