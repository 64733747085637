
.zoomAbout2 {
  cursor: pointer;
  transition: transform .3s; 
  color: white; 
  margin: 0 auto; 
  opacity: 0.6;
}

.zoomAbout2:hover {
  transform: scale(1.2); 
  color: #DDD50E; 
  opacity: 1;
}
