.App {
  text-align: center; 
}


html{
  scroll-behavior: smooth;
  }
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main3{
min-height: 100vh;
background-color: black;
}
.main2{
  /* min-height: 100vh; */
  background-color: black;
  }
.home{
scroll-snap-type:y mandatory;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100vh;
}
section{
  scroll-snap-align: start;
  height: 100vh;
}


@font-face {
  font-family: Lato;
  src: url(./assets/fonts/Lato-Regular.ttf);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.css-ahj2mt-MuiTypography-root {
font-family: "Lato" !important;
}
.css-ag7rrr-MuiTypography-root{
  /* font-family: "Lato", sans-serif !important; */
  font-family: "Lato" !important;
}
.p{
  font-family: "Lato", sans-serif !important;
}


.App-header {

  min-height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .image-gallery {
  position: relative;
}

.main-image img {
  width: 300px; 
  height: 200px;
}

.popup {
  display: none;
  position: absolute;
  top: 0;
  left: 100%; 
}

.main-image:hover + .popup,
.popup:hover {
  display: block;
}

.popup img {
  width: 150px; 
  height: 100px;
} */


/* Define the container for the image */
@keyframes moveFromBottomToCenter {
  0% {
    transform: translateY(200%); /* Start from bottom */
  }
  100% {
    transform: translateY(0%); /* End at center */
  }
}

.image-container {
margin-top: -8rem;
  position: relative;
  height: 100%; /* Adjust the height as per your requirement */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: "5rem";
}
.rotate-image{
  animation: App-logo-spin 2s forwards; /* 1s duration */
}

.moving-image {
  
  animation: moveFromBottomToCenter 2s forwards; /* 1s duration */
}

@keyframes moveFromRightToLeft {
  0% {
    transform: translateX(0%); /* Start from bottom */
  }
  100% {
    transform: translateY(10%); /* End at center */
  }
}

/* .project1{
  transform: rotate(10deg);
} */

.moving-image-roject1 {
  
  animation: moveFromRightToLeft 1s forwards; /* 1s duration */
}

.bouncing-image {


  animation: bounce 0.5s forwards;
 
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0) rotate(-15deg);
  }
  50% {
    transform: translateY(-20px) rotate(-15deg);
  }
  
}


.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
}
.css-11b3ww9-MuiPaper-root-MuiAppBar-root{
  background-color: white !important;
}
.css-p7bec2-MuiTypography-root {
  color: black !important;
  text-align:start !important;
}



.fadeIn {
  display: flex;
  justify-content: center;
  animation: fadeInAnimation ease 8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
.css-heg063-MuiTabs-flexContainer {
justify-content: center;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
  padding: 0rem !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #DDD50E !important;
}
.css-1aquho2-MuiTabs-indicator{
  background-color: #DDD50E !important;
  /* left: 380px !important; */
  /* width: 10%!important; */
}

.css-1q2h7u5.Mui-selected {
  color: #DDD50E !important;
}
.css-ttwr4n {
  background-color: #DDD50E !important;
}
.css-1q2h7u5{
  color: white !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
  color: white !important;
}
.css-heg063-MuiTabs-flexContainer {
  overflow: scroll;
  scrollbar-width: none;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
  font-size: 1.2rem !important;
}

.zoom {
  cursor: pointer;
  transition: transform .2s; 
  color: white; 
  margin: 0 auto; 
}

.zoom:hover {
  -ms-transform: scale(1.05); 
  -webkit-transform: scale(1.05); 
  transform: scale(1.05); 
  color: #DDD50E; 
}


.css-1onwfq{
  display: flex !important;
  justify-content: center !important;
}

.textbottom1 {
  display: none;
}

.zoomAbout:hover + .textbottom1 {
  display: flex;
}

/* About CSS */



/* .zoomAbout {
  cursor: pointer;
  transition: transform .3s; 
  color: white; 
  margin: 0 auto; 
opacity: 0.6;
}

.zoomAbout:hover {
  -ms-transform: scale(1.2); 
  -webkit-transform: scale(1.2); 
  transform: scale(1.2); 
  color: #DDD50E; 
  opacity: 1;
} */
.zoomedImg{
  -ms-transform: scale(1.2); 
  -webkit-transform: scale(1.2); 
  transform: scale(1.2); 
}
/* About CSS */
.vl {
  border-left: 0.5px solid white;
  margin-top: 1rem;
  height: 40px;
}

.vl1 {
  border-left: 0.5px solid black;
  margin-top: 1rem;
  height: 40px;
}

/* .headerMenu:hover {
  border-bottom: 1px solid white;
  padding-bottom: 4px; 


} */
.HomeMenuMobile{
  display: none;
}
.HomeMenu{
  display: flex;
}
.aboutHeaderMobile{
  display: none;
}
.aboutHeader{
  display: contents;
}
.SecondSection{
  display: contents;
 }
 .SecondSectionMobile{
  display: none;
 }


 .workHeader{
  display: flex;
 }
 .workHeaderMobile{
  display: none;
 }


@media only screen and (max-width: 400px) {
  .App-header {
margin-top: 13%;
    min-height: 100vh;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
}

@media only screen and (max-width: 900px) {
 .HomeMenu{
display: none;

 }
 .displayMobile{
  display: none;
 }
 .HomeMenuMobile{
  display: contents;
}
.aboutHeaderMobile{
  display: contents;
}
.aboutHeader{
  display: none;
}
 .image-container{
width: 100%;
 }
 .moving-image1{
  width: 25% !important
 }
 .moving-image{
  width: 11% !important
 }
 .SecondSection{
  display: none;
 }
 .SecondSectionMobile{
  display: contents;
 }
 .workHeader{
  display: none;
 }
 .workHeaderMobile{
  display: flex;
 }

}

@keyframes moveUpAndDisappear {
  0% {
      opacity: 1;
      transform: translateY(0);

  }
  100% {
      opacity: 0;
      display: none;
      transform: translateY(-100px); 
  }
}

.animated-text {
  animation: moveUpAndDisappear 2s forwards; 
  
}

@keyframes resetPosition {
  to 
  0% {
    transform: rotate(30deg);
  }
  10% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
    position: static;
  }


}



.animatedImg1 {
  position: absolute;
  transform: rotate(30deg);
  transition: transform .9s; 
  animation: resetPosition 2s 1.7s forwards; 
  
}

.animatedImg2 {
  position: absolute;
  transform: rotate(20deg);
  transition: transform .7s; 
  animation: resetPosition 2s 1.7s forwards; 
}

.animatedImg3 {
  position: absolute;
  transform: rotate(10deg);
  transition: transform .5s; 
  animation: resetPosition 2s 1.5s forwards; 
}

.animatedImg4 {
  position: absolute;
  transition: transform .3s; 
  animation: resetPosition 2s 1.3s forwards; 
}

@keyframes resetPosition1 {
  to {
    width: 60%;
      
  }
}

.imgWidth{
width: 60%;
/* animation: resetPosition1 2s 3s forwards;  */
}



@keyframes fadeInUp {
  0% {
    transform: translateY(100px);
    opacity: 0;

  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.fadeInUp-animation {
  animation: 1s fadeInUp;
}


.css-1160xiw-MuiPaper-root-MuiDrawer-paper{
  min-width: 70% !important;
}
.css-1ab2xsx{
  min-width: 70% !important;
}
.css-1q2h7u5{
  padding: 0rem !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

/* .animatedImg3:hover img {
  transform: scale(1.2);
  transition: transform 0.3s ease; 
}

.animatedImg4:hover img {
  transform: scale(1.2);
  transition: transform 0.3s ease; 
}
.animatedImg2:hover img {
  transform: scale(1.2);
  transition: transform 0.3s ease; 
}
.animatedImg1:hover img {
  transform: scale(1.2);
  transition: transform 0.3s ease; 
} */

/* https://www.figma.com/file/y9gvaig6UbYU1ZxSI7VaXK/Portfolioooooooo?type=design&node-id=0-1&mode=design&t=spLFkoDoaIR6zepp-0 */